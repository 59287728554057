window.channelPluginSettings = {
  plugin_id: 'ef522633-49fb-4e6d-81c9-cc08ab6b6efd',
  custom_launcher_selector: '#live-chat',
  hide_default_launcher: true,
};

(function() {
   const node = document.createElement('div');
   node.id = 'ch-plugin';
   document.body.appendChild(node);
   const async_load = function() {
       var s = document.createElement('script');
       s.type = 'text/javascript';
       s.async = true;
       s.src = '//cdn.channel.io/plugin/ch-plugin-web.js';
       var x = document.getElementsByTagName('script')[0];
       x.parentNode.insertBefore(s, x);
   };
   if (window.attachEvent) {
       window.attachEvent('onload', async_load);
   } else {
       window.addEventListener('load', async_load, false);
   }
})();
