import { CURRICULUM } from '../actions/ActionTypes';
import { updateReducer } from '../utils/updateReducer';
import { initReducer } from '../utils/initReducer';
import { shuffle } from '../utils/shuffle';

const INITIAL_STATE = {
  list: [],
  detail: {},
};

export default (
  state = {
    ...initReducer(CURRICULUM),
    ...INITIAL_STATE,
  },
  action,
) => {
  switch (action.type) {
    case CURRICULUM.GET_LIST.REQUEST:
      return updateReducer(state, action);
    case CURRICULUM.GET_LIST.RESPONSE: {
      if (!action.error) {
        const list = Object.entries(action.payload)
          .map(([key, data]) => {
            return {
              ...data,
              key,
              value: data.title,
            };
          });
        return {
          ...updateReducer(state, action),
          list: shuffle(list),
        };
      }
      return updateReducer(state, action);
    }
    case CURRICULUM.GET_LIST.CANCEL:
      return updateReducer(state, action);

    case CURRICULUM.GET_DETAIL.REQUEST:
      return updateReducer(state, action);
    case CURRICULUM.GET_DETAIL.RESPONSE: {
      if (!action.error) {
        const { key, data, team = [] } = action.payload;

        const detail = data || {
          summary: '',
          content: '',
          place: '',
        };

        return {
          ...updateReducer(state, action),
          detail: {
            ...state.detail,
            [key]: {
              ...detail,
              team: shuffle(team),
            },
          },
        };
      }
      return updateReducer(state, action);
    }
    case CURRICULUM.GET_DETAIL.CANCEL:
      return updateReducer(state, action);

    case CURRICULUM.CREATE_CURRICULUM.REQUEST:
      return updateReducer(state, action);
    case CURRICULUM.CREATE_CURRICULUM.RESPONSE: {
      if (!action.error) {
        const { key, data } = action.payload;
        return {
          ...updateReducer(state, action),
          list: [
            ...state.list,
            {
              ...data,
              key,
              value: data.title,
            }
          ],
        };
      }
      return updateReducer(state, action);
    }
    case CURRICULUM.CREATE_CURRICULUM.CANCEL:
      return updateReducer(state, action);

    case CURRICULUM.UPDATE_DETAIL.REQUEST:
      return updateReducer(state, action);
    case CURRICULUM.UPDATE_DETAIL.RESPONSE: {
      if (!action.error) {
        const { key, data } = action.payload;

        return {
          ...updateReducer(state, action),
          detail: {
            ...state.detail,
            [key]: data,
          },
        };
      }
      return updateReducer(state, action);
    }
    case CURRICULUM.UPDATE_DETAIL.CANCEL:
      return updateReducer(state, action);

    default:
      return state;
  }
}
