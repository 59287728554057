import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
  projectId: 'sullivan-project',
  apiKey: 'AIzaSyDT7rRVYn5jw1Fx6gqTMHYujdwJsjwTZNU',
  authDomain: 'sullivan-project.firebaseapp.com',
  databaseURL: 'https://sullivan-project.firebaseio.com/',
  storageBucket: 'sullivan-project.appspot.com',
};

firebase.initializeApp(config);

export const provider = new firebase.auth.GithubAuthProvider();
export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();

export default firebase;
