export const ASYNC = 'ASYNC';

export const STATUS = {
  REQUEST: 'REQUEST',
  RESPONSE: 'RESPONSE',
  CANCEL: 'CANCEL',
};

export const KEY = {
  ESC: 'Escape',
  ENTER: 'Enter',
  TAB: 'Tab',
  SPACE: ' ',

  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',

  HOME: 'Home',
  END: 'End',
};

export const REGION = [
  { key: 'seoul', value: '서울' },
  { key: 'gyeonggi', value: '경기' },
  { key: 'incheon', value: '인천' },
  { key: 'gangwon', value: '강원' },
  { key: 'daejeon', value: '대전' },
  { key: 'daegu', value: '대구' },
  { key: 'sejong', value: '세종' },
  { key: 'ulsan', value: '울산' },
  { key: 'busan', value: '부산' },
  { key: 'jeju', value: '제주' },
  { key: 'chungnam', value: '충남' },
  { key: 'chungbuk', value: '충북' },
  { key: 'jeonnam', value: '전남' },
  { key: 'jeonbuk', value: '전북' },
  { key: 'gyeongnam', value: '경남' },
  { key: 'gyeongbuk', value: '경북' },
];

export const CATEGORY = [
  { key: 'web', value: 'Web' },
  { key: 'app', value: 'App' },
  { key: 'game', value: 'Game' },
  { key: 'iot', value: 'IoT' },
];

export const METADATA = {
  CATEGORY: CATEGORY
    .reduce((obj, { key, value }) => ({ ...obj, [key]: value }), {}),
  REGION: REGION
    .reduce((obj, { key, value }) => ({ ...obj, [key]: value }), {}),
};

export const DATE = [
  { key: 'mon', value: '월' },
  { key: 'tue', value: '화' },
  { key: 'wed', value: '수' },
  { key: 'thu', value: '목' },
  { key: 'fri', value: '금' },
  { key: 'sat', value: '토' },
  { key: 'sun', value: '일' },
];

export const FOCUSABLE_ELEMENT =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
