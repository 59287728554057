export function scrollAnimation(element, to) {
  let currentTime = 0;

  const start = document.scrollingElement.scrollTop;
  const change = to - start;
  const increment = 20;
  const duration = 600;

  const animation = function() {
    const easeInOutQuad = function(t, b, c, d) {
      t /= d / 2;

      if (t < 1) {
        return (c / 2) * t * t + b;
      }

      t -= 1;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    currentTime += increment;

    const val = easeInOutQuad(currentTime, start, change, duration);
    document.scrollingElement.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animation, increment);
    }
  };

  animation();
}
